(function () {
    var tabCollection = document.querySelectorAll('*[data-tab');
    var tabContentCollection = document.querySelectorAll('*[data-tab-content');

    function tabActiveStateHandler() {
        var self = this;
        tabCollection.forEach(function (item) {
            item.classList.remove('active');
            if(self === item) item.classList.add('active');
        });
    }

    function tabHandler() {
        var self = this;
        tabContentCollection.forEach(function (item) {
            item.style.display = 'none';
            if(item.dataset.tabContent === self.dataset.tab) {
                item.style.display = 'block';
            }
        })
    }

    if(tabCollection.length) {
        tabCollection.forEach(function (item) {
            item.addEventListener('click', tabActiveStateHandler);

            if(tabContentCollection.length) {
                item.addEventListener('click', tabHandler);
            }
        })
    }

})();